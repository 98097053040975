.createad-container {
  padding-top: 70px;
  padding-right: 40px;
  display: flex;
  background-color: rgb(252, 252, 252);
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.createdad-wrapper {
  padding: 10px;
  margin: 0;
  width: 100%;
  display: flex;
}

.create-ad-main-container-svante {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  justify-content: center;
  width:100%;
  height:fit-content;
  gap: 1rem 1.4rem;
}

.create-ad-image-container-svante {
  justify-content: center;
  position: relative;
}

.create-ad-form-container-svante {
  width: 600px;
  margin-top: 3rem;
}

.create-ad-description-container-svante {
  flex-grow: 1;
  max-width: 1120px;
  width:100%;
}

.createad-property-container {
  margin: 20px;
}

.createdad-wrapper h4 {
  font-size: 1.2rem;
}

.createad-subblock,
.createad-category-container {
  text-align: left;
}

.createad-subblock.ad-description {
  flex-grow: 1;
  text-align: center;
  width: 1000px;
}

.createad-field-container {
  width: 100%;
}

.createad-profile-container {
  width: 300px;
}

.createad-container-content {
  padding-bottom: 100px;
}

.ad-tips {
  font-size: 12px;
  float: right;
  cursor: pointer;
  padding: 5px 10px;
  width: fit-content;
  background-color: var(--lgrey);
  transition: 0.2s;

  &:hover {
    background-color: var(--dblue);
    color: white !important;
  }
}

.createad-container-content-text {
  display: block;
  text-align: center;
  font-size: 17px;
  letter-spacing: 0.85px;
  color: #909090;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 20px;
}

.createad-container-block {
  width: 100%;
  margin: 0 auto;
  display: flex;
}

select.options {
    width: 100%;
    border: 1px solid grey;
    padding: 10px;
    border-right: 5px solid #344155;
}

select.validated,
input.validated[type="text"],
input.validated[type="number"],
textarea.validated,
input.validated[type="password"] {
}

.options-undercategory-living-container {
  width: 50%;
  padding-bottom: 5px;
}

.options-undercategory-vehicles-container {
  width: 50%;
  padding-bottom: 5px;
}
.createad-undercategory-wrapper {
  padding-bottom: 5px;
}

.options-undercategory-boats-container,
.options-undercategory-column-two-container-left {
  width: 50%;
  padding-right: 5px;
}

.options-undercategory-column-two-container-right {
  width: 50%;
}

select.options-undercategory {
    width: 100%;
    border: 1px solid grey;
    padding: 10px;
    border-right: 5px solid #344155
}

select.options-undercategory-living {
}

.createdad-fashion-container {
}

.createdad-fashion-information-container {
  width: 100%;
  max-width: 400px;
  display: flex;
}

.createdad-bygg-information-left {
  width: 100%;
  padding-top: 3px;
}

.createdad-bygg-information-right {
  width: 50%;
  padding-top: 3px;
}

.createdad-fashion-information-left {
  width: 50%;
  padding-top: 3px;
}

.createdad-fashion-information-right {
  width: 50%;
  padding-top: 3px;
  padding-left: 3px;
}

input.createad-container-title[type="text"] {
    width: 95.5%;
    border: 1px solid grey;
    padding: 10px;
    border-right: 5px solid #344155;
}

input.createad-container-price[type="number"] {
    width: 100%;
    border: 1px solid grey;
    padding: 10px;
    border-right: 5px solid #344155;
}

.createad-container-price {
  display: flex;
  width: 130px;
  padding-bottom:5px;
}

.createad-container-price-value{
  vertical-align: baseline;
  font-size: 13px;
  letter-spacing: 0.75px;
  padding: 5px;
}

.save-ad-button-svante {
  display: flex;
  justify-content: center;
}

textarea.createad-container-textarea {
  width: 100%;
  border: 1px solid grey;
  padding: 10px;
  border-right: 5px solid #344155;
  font-size: 13px;
  letter-spacing: 0.75px;
  padding-left: 10px;
  min-width: 300px;
  padding-top: 5px;
  resize: none;
  box-sizing: border-box;
}

.createad-container-ad {
  width: 60%;
  padding: 20px 20px 5px 20px;
}

.createad-container-user {
  width: 40%;
  height: fit-content;
  padding: 20px 20px 5px 20px;
}

.createad-container-user-name {
  font-size: 17px;
  font-weight: bold;
  text-align: center;
}

.createdad-avatar-container {
  width: 100%;
  max-width: 100px;
  margin: 0 auto;
  padding-bottom: 10px;
}

.createdad-name-container {
  width: 100%;
  max-width: 250px;
  margin: 0 auto;
}

.createad-container-sub {
  width: 250px;
  background-color: rgb(221, 76, 54);
  margin: 10px;
  padding: 10px;
  text-align: center;
  color: white;
  font-weight: bold;
  border-radius: 2px;
  cursor: pointer;
}

.createad-container-sub:hover {
  background-color: rgb(242, 99, 77);
}

.createad-container-sub-info-text,
.createad-container-sub-text {
  margin-left: 5px;
}

.createad-avatar {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;

  @media( max-width: 768px ) {
    width: 100%;
    height: auto;
  }
}

.createad-info-icon {
  color: rgb(52, 64, 86);
  cursor: pointer;
}
.createad-info-icon:hover {
  color: rgb(221, 76, 54);
}

.createad-google-map {
  margin: 10px;
}

.createad-container-sub-info {
  color: #505050;
  margin: 10px;
  padding: 10px;
  width: 250px;
  cursor: pointer;
}

.createad-container-sub-info:hover {
  background-color: rgb(237, 231, 231);
  color: black;
}

.createad-form-submit-button {
  background-color: var(--dblue);
  min-width: 300px;
  height: 40px;
  font-size: 17px;
  color: white;
  font-weight: bold;
  text-align: center;
  border: none;
  border-radius: 2px;
  cursor: pointer;
}

.createad-container-submit-button {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  padding-bottom: 100px;
  padding: 0px 20px 20px 20px;
}

.createad-form-submit-button:hover {
  background-color: rgb(241, 87, 64);
}

.upload- {
  max-width: 400px;
  min-width: 400px;
  margin: 0;
}

.upload-button{
    height: 20px;
    position: relative;
    margin-bottom: 2rem;

    @media(max-width: 768px) {
      margin-bottom: 0;
    }
}

.upload- ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-left: 0;
  padding-right: 0;

  .upload-link {
    display: inline-flex;
  }

  .upload-link:first-child {
    width: 100%;
    max-width: 100%;
    justify-content: center;
  }

  .upload-link:not(:first-child) {
    flex-grow: 1;
  }
}

.upload-img,
.upload-img-left,
.upload-img-right {
  width: 95px;
  height: 95px;
  object-fit: cover;
  border: 1px solid lightgray;
  cursor: pointer;
}

.upload-img-block {
  width: 70%;
  max-width: 70px;
  margin: 0 auto;
  height: auto;
  object-fit: cover;
  margin-top: 16px;
}

.upload-image-container {
  width: 100px;
  max-width: 95px;
  min-width: 50px;
  height: 100px;
  display: block;
  cursor: pointer;
  text-align: center;
}

.show-added-ads {
  display: show;
}

.upload-block-left,
.upload-block-right,
.upload-block {
  width: 100%;
  background-color: lightgray;
  max-width: 95px;
  min-width: 50px;
  height: 100%;
  max-height: 95px;
  min-height: 50px;
  display: inline-block;
  cursor: pointer;
  border-radius: 2px;
}

.upload-block-left {
  margin: 3px 3px 3px 0;
}

.upload-block {
  margin: 3px;
}

.upload-block-right {
  margin: 3px 0 3px 3px;
}

.upload-link {
  list-style: none;
  display: inline;
  max-width: 400px;
  min-width: 30px;
}

input[type="file"] {
  cursor: pointer;
  width: 180px !important;
  height: 50px;
  overflow: hidden;
  opacity: 0;
  overflow: hidden;
  z-index: -1;
  position: absolute;
}

input[type="file"]::before {
  width: 100%;
  height: 100%;
  font-size: 16px;
  line-height: 32px;
  display: inline-block;
  text-align: center;
  font-weight: bold;
  opacity: 0;
  overflow: hidden;
  z-index: -1;
}

.upload-button-label {
  width: 100%;
  max-width: 300px;
  text-align: center;
  background-color: var(--dblue);
  color: white;
  border-radius: 2px;
  line-height: 40px;
  left:50px;
  vertical-align: middle;
  position: absolute;
  cursor: pointer;
}

input[type="file"]::-webkit-file-upload-button {
  visibility: hidden;
}

.upload-head-container {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
}

.upload-block-left,
.upload-block-right,
.upload-block {
  position: relative;
}

.upload-head-container:hover {
  cursor: pointer;
}

.upload-head-img {
  width: 100%;
  max-width: 400px;
  min-width: 300px;
  height: 300px;
  max-height: 300px;
  cursor: pointer;
}

.upload-head-img {
  object-fit: scale-down;
}

.upload-head-img-active {
  object-fit: cover;
  border: 1px solid lightgray;
  max-height: 300px;
  max-width: 400px;
  padding: 10px;
}

.upload-head-cancel-icon {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 6px;
  right: 10px;
  color: red;

  svg {
    background-color: white;
  }
}

.upload-head-cancel-icon:hover {
  color: rgb(241, 87, 64);
}

.upload-cancel-icon {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 0;
  right: 10px;
  color: red;

  svg {
    background-color: white;
  }
}

.upload-cancel-icon:hover {
  color: rgb(241, 87, 64);
}

.upload-head-img:hover,
.upload-block:hover,
.upload-block-left:hover,
.upload-block-right:hover {
  background-color: rgb(215, 215, 215);
}

.options-undercategory-under {
}

.options-undercategory-boats-active {
  display: flex;
  width: 100%;
  max-width: 400px;
  padding-bottom: 2px;
}

.options-undercategory-apartment {
  width: 100%;
  margin: 0 3px 0 0;
}

.options-undercategory-apartment-information-options {
  width: 33.33%;
  margin-right: 10px;
}

.options-undercategory-apartment-information {
  width: 33.33%;
  display: inline-table;
  margin: 3px 0 3px 3px;
}

.options-undercategory-information-prefix {
  // display: table-cell;
  vertical-align: baseline;
  font-size: 13px;
  letter-spacing: 0.75px;
  padding: 5px;
}

.options-undercategory-apartment-information-container {
  width: 100%;
  display: flex;
}

.options-undercategory-vehicles-column-boats,
.options-undercategory-column-two {
  width: 100%;
  cursor: pointer;
}

.options-undercategory-column {
  width: 100%;
}

.options-undercategory-column-left {
  width: 50%;
}

.options-undercategory-vehicles-column-left {
  width: 50%;
  padding-bottom:5px;
}

.options-undercategory-vehicles-column-right {
  width: 50%;
  padding-bottom:5px;
}

.options-undercategory-vehicles-left {
  width: 33.33%;
  padding-bottom:5px;
}

.options-undercategory-vehicles {
  width: 33.33%;
  padding-bottom:5px;
}

.options-undercategory-vehicles-right {
  width: 33.33%;
  padding-bottom:5px;
}

.options-undercategory-vehicles-information {
  width: 33.33%;
  display: inline-table;
  padding-bottom:5px;
}

.options-title{
  width: 100%;
  padding-bottom:5px;
}

.createad-container-content select,
.createad-container-content input {
  padding: 5px;
}

.ad-details-info {
  text-align: center;
  max-width: 80%;
  padding: 1rem;
  background-color: rgba(0,0,0,0.1);

  a {
    color: var(--dorange);
  }

  @media(max-width: 768px) {
    max-width: 100%;
  }
}

.editad-findme {
  text-decoration: none;
  text-align: center;
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
  color: var(--dorange);
  font-size: 0.8rem;
  padding-left: 3px;
}

select.options-undercategory-living-active {
  width: auto;
    border: 1px solid grey;
    padding: 10px;
    border-right: 5px solid #344155;
    color: #000;
}

input.options-undercategory-living-active[type="number"] {
  width: auto;
    border: 1px solid grey;
    padding: 10px;
    border-right: 5px solid #344155;
    color: #000;
}

input.options-undercategory-vehicles-active[type="number"] {
  width: 80%;
    border: 1px solid grey;
    padding: 10px;
    border-right: 5px solid #344155;
    color: #000;
    box-sizing: border-box;
}

select.options-undercategory-vehicles-active {
  width: 80%;
    border: 1px solid grey;
    padding: 10px;
    border-right: 5px solid #344155;
    color: #000;
}

select.options-undercategory-vehicles-boat,
select.options-undercategory-children-sizes {
  width: 95.5%;
    border: 1px solid grey;
    padding: 10px;
    border-right: 5px solid #344155;
}

.options-undercategory-vehicles-gearbox {
  width: 95.5%;
    border: 1px solid grey;
    padding: 10px;
    border-right: 5px solid #344155;
}

.options-undercategory-container {
  
}

.options-title-category{
  padding-bottom:4px;
}

.options-undercategory-container-vehicles {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}

.options-undercategory-apartment-container {
  display: flex;
  width: 100%;
  max-width: 400px;
  padding-top: 3px;
  margin-left: 2px;
}

@media screen and (max-width: 920px) {

  input.createad-container-title[type="text"]{
    width:92%;
  }

  .upload- ul {
    
  }

  .upload-button-label{
    max-width: none;
  }

  .createad-form-submit-button{
    width: 100%;
  }

  .my-page-container{
    padding:20px;
  }

  .create-ad-main-container-svante h4{
    padding:10px;
  }

  .create-ad-form-container-svante {
    max-width: 340px;
    margin: 0 auto;;
  }

  .create-ad-description-container-svante {
    max-width: 340px;
    margin: 0 auto;
  }

  .createad-subblock.ad-description{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width:350px;
  }

  .create-ad-main-container-svante{
    height:100%;
    width: 95vw;
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .create-ad-image-container-svante {
    margin-left: 0px;
  }

  .create-ad-form-container-svante h4 {
    font-size: medium;
    margin: 0px;
  }

  .create-ad-description-container-svante h4 {
    font-size: medium;
    margin-left: 0px;
    flex-grow: 0;
  }

  .upload-head-container {
    // max-width: 300px;
    max-height: 300px;
  }

  .upload-head-img-active {
    max-width: 300px;
    max-height: 300px;
  }

  .upload-link {
    width: fit-content;
    max-width: fit-content;
  }

  .upload-button-label {
    position: absolute;
    left: 0px;
    margin-left: 0px;
  }

  .upload-head-img {
    max-height: 200px;
    max-width: 200px;
  }

  .createad-container-user {
    display: none;
  }

  .createad-container-block {
    padding: 0;
  }

  .upload- {
    display: flex;
    flex-grow: 1;
  }

  .createad-property-container {
    flex-grow: 1;
    margin: 0;
  }

  .createad-subblock.ad-description {
    flex-grow: 1;
  }
}

@media screen and (max-width: 1024px) {
  .createad-profile-container {
    display: none;
  }

  .createdad-wrapper {
    flex-wrap: wrap;
    padding: 0;
  }

  .upload- {
    display: flex;
    flex-direction: column;
    min-width: unset;
    max-width: unset;
    margin-bottom: 2rem;
  }
}

.validated {
  background-color: lightyellow;
}

.treddy {
  padding: 1rem;
  border: 2px solid #155d64;
  font-size: 0.8rem;
  text-align: center;
  display: flex;
  align-items: center;
  max-width: 700px;
  margin: 1rem auto;
  flex-direction: column;

  label {
    line-height: 2rem;
  }

  a {
    color: #666;
  }
}