.container-svante {
  display: grid;
  position: relative;
  grid-template-columns: minmax( 0, 100%) auto;
  padding-bottom: 20px;

  @media( max-width: 768px ) {
    grid-template-rows: auto auto;
    grid-template-columns: unset;
    padding: 0.5rem;
  }
}

.chosenad.mainwrapper {
  width: 100%;
}

.headImage-svante-wrapper {
  position: relative;
}

.headImage-svante-wrapper img {
  float: left;
  width: 100%;
  max-height: 600px;
  max-width: 800px;
  object-fit: contain;
}

li {
  list-style: none;
}

.chosenad-prev-image,
.chosenad-next-image {
  display: flex;
  position: absolute;
  top: calc( 50% - 1rem );
  padding: 0;
  margin: 0;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,0.5);
  font-size: 1.2rem;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  border-radius: 100%;
  transition: 0.2s;
  color: #FFF;

  &:hover {
    background-color: var(--dorange);
  }
}

.chosenad-next-image {
  right: 0;
}

.chosenad-prev-image {
  left: 0;
}

.secondary-image-svante {
  width: 100px;
  max-height: 67px;
  object-fit: contain;
  cursor: pointer;
}
.title-svante {
  padding-top: 20px;
  font-style: normal;
  font-weight: normal;
  font-size:28px;
  line-height: 42px;
  flex-direction: row;
}
.title-price-container-svante {
  display: flex;
  flex-direction: column;
}
.beskrivning-svante {
  padding-top: 10px;
  padding-bottom: 10px;
}
.price-svante {
  position: relative;
  float: left;
  padding-top: 10px;
  font-size: 1.2rem;
  color: var(--dorange);
}

.chosenad-info-where-and-when{
  justify-self: flex-end;
  font-size: 0.7rem;
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: flex-end;
  padding-right: 0.5rem;

  svg {
    font-size: 0.9rem;
  }

  // @media( max-width: 768px ) {
  //   font-size: 0.7rem;
  // }
}
.svanterutan {
  position: relative;
  padding: 0 0 1rem 0;
}

.chosenad-share-wrapper {
  display: flex;

  .chosenad-share-prefix {
    display: flex;
    height: 32px;
    padding: 0 0.5rem;
    background-color: var(--dblue);
    color: white;
    font-size: 0.9rem;
    font-weight: bold;
    align-items: center;
    flex-grow: 1;
  }
}

.chosenad-treddybutton {
  background-color: #155d64 !important;

  &:hover {
    background-color: var(--dorange) !important;
  }

  img {
    background-color: #FFF;
    border-radius: 100%;
    padding: 3px;
    margin-right: 0.5rem;
  }
}

.chosenad-treddyinfolink {
  display: block;
  font-size: 0.8rem;
  text-align: center;
  color: #666;
}

@media screen and (max-width: 1025px) {
  ul {
    display: inline-flex;
  }
}

@media screen and (max-width: 920px) {
  .container-svante {
    justify-content: center;
    padding-bottom: 0px;
  }
  
  .beskrivning-svante{
    margin-bottom: 3rem;
  }

  .title-svante {
    padding-top: 0px;
    font-size: 26px;
  }

  .price-svante {
    float: none;
  }

  .ort-svante{
    float: none;
    text-align: left;

  }

  .headImage-svante-wrapper img {
    float: left;
    max-width: 95vw;
    max-height: 300px;
    object-fit: contain;
    //padding-right: 10px;
  }
  .secondary-image-svante {
    // max-width: 70px;
    height: 60px;
    object-fit: cover;
    overflow: hidden;
  }

  .small-pic-wrapper-svante ul {
    display: grid;
    gap: 5px 5px;
    grid-template-columns: 30% 30% 30%;
    padding: 0;
    justify-content: center;
  }
  
  .small-pic-wrapper-svante li {
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
}

.annons-attribut {
  margin: 2rem;
  background-color: white;
  border: 1px solid var(--bordergrey);
  border-radius: 3px;

  ul {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    gap: 1rem 0;
  }

  li {
    cursor: unset;
    display: block;
  }

  @media( max-width: 768px ) {
    padding: 0 1rem;

    ul {
      grid-template-columns: 100%;
      padding-left: 0;
    }
  }
}

.chosenad-container {
  width: 100%;
  max-width: var(--avgwidth);
  margin: 0 auto;
  // background-color: #FFF;
  margin-bottom: 30px;
  user-select: text;
}

.chosenad-banner-container {
  width: 100%;
  max-width: fit-content;
  margin: 0 auto;
  //padding-top: 145px;
  padding-bottom: 10px;
}

.chosenad-container-content {
  padding-bottom: 100px;
}

.chosenad-wrapper {
  padding: 0;
  border-radius: 2px;
  width: 100%;
  margin: 0 auto;
}

.chosenad-container-block {
  width: 100%;
  height: fit-content;
  display: grid;
  grid-template-columns: auto 320px;

  @media( max-width: 768px ) {
    grid-template-columns: unset;
    grid-template-rows: auto auto;
  }
}

.chosenad-container-ad {
  //width: 51%;
  //padding: 30px 20px 5px 0px;
  padding: 30px 0 0 0;
  flex-grow: 1;
}

.chosenad-title-info-container {
  width: 100%;
  padding: 10px;
  letter-spacing: 1px;
  font-size: 1.5rem;
  height: 55px;
  color: rgb(77, 76, 76);
}

.chosenad-info-bottom-container {
  width: 100%;
  display: flex;
  letter-spacing: 1px;
  font-size: 1.5rem;
  color: rgb(77, 76, 76);
  padding-bottom: 0;
  justify-content: center;
}

.chosenad-title-container {
  padding: 10px 0 5px 0px;
}

.chosenad-price {
  color: rgb(221, 76, 54);
}

.chosenad-category-container,
.chosenad-homepage {
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-right: 0px;
}

.chosenad-info-container {
  font-size: 0.76rem;
  color: #575555;
  width: 100%;
  max-width: var(--avgwidth);
  margin: 0 auto;
  background-color: white;
  border: 1px solid var(--bordergrey);
  text-transform: uppercase;
  letter-spacing: 1.2px;
  display: flex;
  box-sizing: border-box;

  @media(max-width: 768px) {
    width: calc( 100vw - 1rem );
  }
}

.chosenad-go-back {
  background-color: rgb(52, 64, 86);
  color: white;
  padding: 5px;
  width: 50px;
  text-align: center;
  border-radius: 2px;
}

.chosenad-homepage,
.chosenad-info-title {
  margin-left: 10px;
  margin-right: 0px;
  display: flex;
  align-items: center;

  span {
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.chosenad-info-date {
  display: flex;
  align-items: center;
  text-align: right;
  margin-left: auto;
  padding-right: 10px;
}

.chosenad-info-link {
  display: inline-flex;
}

.chosenad-category,
.chosenad-undercategory {
  padding: 4px;
  border-radius: 3px;
}

.chosenad-category:hover,
.chosenad-undercategory:hover {
  background-color: #eee;
}

.chosenad-container-region,
.chosenad-container-date {
  font-size: 0.8rem;
}

.chosenad-images-container {
  padding: 0 0 0 10px;
}

.chosenad-images-link {
  list-style: none;
  display: inline;
  width: 100%;
  max-width: 700px;
}

.chosenad-images-head-container {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
}

.chosenad-head-img {
  max-width: 700px;
  height: auto;
  max-height: 500px;
  cursor: pointer;
  background-color: lightgray;
  object-fit: cover;
  box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.4);
}

.chosenad-image-left,
.chosenad-image-right,
.chosenad-image {
  width: 25%;
  height: 100%;
  max-height: 95px;
  display: inline-block;
  cursor: pointer;
  border-radius: 2px;
}

.chosenad-image-left {
  margin: 3px 3px 3px 0;
}

.chosenad-image {
  margin: 3px;
}

.chosenad-image-right {
  margin: 3px 0 3px 3px;
}

.chosenad-img,
.chosenad-img-left,
.chosenad-img-right {
  width: 100%;
  object-fit: cover;
  cursor: pointer;
}

.chosenad-image-container {
  width: 100%;
  height: auto;
  display: inline-block;
  cursor: pointer;
  text-align: center;
}

.chosenad-description-container {
  width: 100%;
  height: fit-content;
  border-radius: 4px;
  padding: 10px;
  margin: 5px;
  letter-spacing: 1px;
  font-size: 0.95rem;
  color: rgb(80, 79, 79);
  line-height: 24px;
}

.chosenad-seller-text {
  letter-spacing: 1px;
  font-size: 0.95rem;
  text-align: center;
}

.chosenad-container-user {
  display: flex;
  // min-width: 350px;
  height: fit-content;
  padding: 20px;
  background-color: rgb(228, 228, 228);
  border-radius: 3px;
  justify-content: center;
  flex-direction: column;
  position: relative;
  max-width: 95vw;
  margin: 30px 0 0 30px;
  
  @media( max-width: 768px ) {
    max-width: unset;
    padding: 0.5rem;
    position: sticky;
    border-radius: 0;
  }
}

.chosenad-container-user-name,
.chosenad-container-avatar {
  font-size: 17px;
  padding: 10px 0;
  font-weight: bold;
  width: 100%;
  display: flex;
  justify-content: center;

  @media( max-width: 768px ) {
    // padding: 0 0 10px 0;
    padding: 0 0 0.5rem 0;
  }
}

.chosenad-container-sub {
  max-width: 815px;
  background-color: var(--lblue);
  margin: 10px 0;
  padding: 10px;
  text-align: center;
  color: white;
  font-weight: bold;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chosenad-container-sub svg {
  margin-right: 0.3rem;
}

.chosenad-container-sub:hover {
  background-color: rgb(242, 99, 77);
}

.chosenad-container-sub-info-text,
.chosenad-container-sub-text {
  margin-left: 5px;
}

.chosenad-container-sub-link {
  color: white;
  text-decoration: none;
  list-style: none;
  display: flex;
  align-items: center;
}

.chosenad-container-sub-link:hover {
  text-decoration: none;
  color: white;
}

.chosenad-avatar {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  margin: 10px;
}

.chosenad-info-icon {
  color: rgb(52, 64, 86);
  cursor: pointer;
}

.chosenad-info-icon:hover {
  color: rgb(221, 76, 54);
}

.chosenad-profile-userdata {
  display: grid;
  grid-template-rows: auto auto;
  align-items: center;

  @media( max-width: 768px ) {
    // grid-template-columns: 15% auto;
    grid-template-rows: unset;
    display: flex;
    justify-content: center;
    
    .chosenad-container-avatar {
      max-width: 2rem;
      padding-right: 1rem;
    }

    .chosenad-container-user-name {
      width: unset;
    }
  }
}

.chosenad-google-map {
  padding: 0 0.5rem 0.5rem 0.5rem;
  background-color: #e4e4e4;
  margin: 0 0 0 30px;
  // width: 100%;
  box-sizing: border-box;
  max-width: 95vw;

  iframe {
    border: 0;
    width: 100%;
  }

  @media( max-width: 768px ) {
    margin: 0 auto;
    width: 100%;
    max-width: unset;
  }
}

.chosenad-container-sub-info {
  color: #505050;
  margin: 10px 0;
  padding: 10px;
  max-width: 815px;
  cursor: pointer;
  text-align: center;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chosenad-container-sub-info:hover {
  background-color: rgb(237, 231, 231);
}

.chosenad-report {
  width: fit-content;
  margin: 0 auto;
}

.chosenad-report-container {
  font-size: 0.85rem;
  text-align: right;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
  padding: 0.5rem;
  border-radius: 5px;
}

.chosenad-report-container:hover {
  background-color: var(--dblue);
  color: #FFF;
}

.chosenad-report-container svg {
  margin-right: 0.5rem;
}

.chosenad-report-icon {
  color: rgb(221, 76, 54);
}

.chosenad-other-ads-container {
  display: flex;
  width: 100%;
  max-width: var(--maxwidth);
  margin: 0 auto;
  background-color: #fcfcfc;
  margin-bottom: 30px;
  padding: 10px;
  display: none;
}

.chosenad-other-ads-title {
  padding: 10px;
  letter-spacing: 1px;
  font-size: 1rem;
}

@media screen and (max-width: 500px) {
  .chosenad-info-container{
    margin:0;
    margin-left:0.5rem;
  }

  .chosenad-container-ad {
    width: unset;
    margin: 0 auto;
    padding: 0;
  }

  .chosenad-container-block {
    padding: 0;
    display: grid;
  }

  .chosenad-wrapper {
    padding: 10px 0px 20px 0px;
  }
}

@media screen and (max-width: 920px) {
  .chosenad-container-user {
    width: 100vw;
    margin: 0 auto;
    top: unset;
    bottom: 0;
    box-sizing: border-box;
  }

  .title-price-container-svante{
    padding:10px;
  }

  .chosenad-container-ad {
    width: 100%;
    margin: 0;
  }

  .chosenad-container-block {
    padding: 0;
    display: grid;
    margin:0;
  }

  .chosenad-images-container {
    padding: 0;

    ul {
      margin: 0;
      padding: 0;
    }
  }

  .chosenad-info-title {
    display: none;
  }

  .chosenad-info-link .chosenad-homepage,
  .chosenad-info-link .chosenad-category-container {
    display: none;
  }
}

@media screen and (max-width: 758px) {
  .chosenad-price-container {
    font-size: 1rem;
    padding: 10px 0;
    width: 100%;
  }

  .chosenad-description-container {
    margin: 2rem 0;
  }

  .chosenad-report-container {
    padding: 2rem 0 0 0;
    text-align: center;
  }
}

@media screen and (max-width: 1024px) {
  .chosenad-container {
    width: 100%;
    margin: 0;
    padding-right: 0;
  }

  .chosenad-profile-container {
    
    span {
      display: none;
    }
  }

  .chosenad-container-content {
    width: 100%;
    margin: 0 auto;
  }

  .chosenad-seller-text {
    display: none;
  }

  .chosenad-profile-actions {
    display: flex;
    gap: 0.5rem;

    .chosenad-container-sub-info,
    .chosenad-container-sub {
      flex-grow: 1;
      margin: 0;
    }
  }
}
